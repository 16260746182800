import { Button, Card } from '@/components'
import Link from 'next/link'
import getLinkHref from '@/helpers/getLinkHref'
import styles from './Card.module.scss'

export default function EventCard({ areaId }: { areaId: string }) {
  const href = getLinkHref({ type: 'events', data: { areaId } })

  return (
    <Link href={href} passHref>
      <a className={styles.link}>
        <Card className={styles.event}>
          <div className={styles.content}>
            <header>
              <h2 className={styles.title}>Мероприятие</h2>
              <p className={styles.descr}>
                Если вы хотите посетить мероприятие, вы можете приобрести парковочную сессию на
                период мероприятия
              </p>
            </header>
            <Button className={styles.button} color="secondary">
              Приобрести
            </Button>
            <div className={styles.image}>
              <img src="/images/events.png" alt="logo" />
            </div>
          </div>
        </Card>
      </a>
    </Link>
  )
}
