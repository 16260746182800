import { Card, Button, Icon } from '@/components'
import Link from 'next/link'
import getLinkHref from '@/helpers/getLinkHref'
import { CardType, getCardIconName } from '@/helpers/getSlugImages'

import styles from './Card.module.scss'

export default function PaymentCard({ areaId }: { areaId: string }) {
  const href = getLinkHref({ type: 'find-car-number', data: { id: areaId } })

  return (
    <Link href={href} passHref>
      <a className={styles.link}>
        <Card className={styles.payment}>
          <div className={styles.content}>
            <header>
              <h2 className={styles.title}>Оплатить</h2>
              <p className={styles.descr}>Здесь вы можете оплатить активную парковку</p>
            </header>
            <Button className={styles.button} color="secondary" type="button">
              Оплатить
            </Button>
          </div>
          <div className={styles.image}>
            <Icon name={getCardIconName({ slug: areaId, cardType: CardType.PaymentCard })} />
          </div>
        </Card>
      </a>
    </Link>
  )
}
