import { Card, Button, Icon } from '@/components'
import Link from 'next/link'
import getLinkHref from '@/helpers/getLinkHref'
import styles from './Card.module.scss'
import { CardType, getCardIconName } from '@/helpers/getSlugImages'

export default function SubscriptionCard({ areaId }: { areaId: string }) {
  const href = getLinkHref({ type: 'subscriptions', data: { areaId } })

  return (
    <Link href={href} passHref>
      <a className={styles.link}>
        <Card className={styles.subscription}>
          <div className={styles.content}>
            <header>
              <h2 className={styles.title}>Абонемент</h2>
              <p className={styles.descr}>
                Если у вас пока нет абонементов, вы можете их приобрести
              </p>
            </header>
            <Button className={styles.button} color="secondary">
              Приобрести
            </Button>
            <div className={styles.image}>
              <Icon name={getCardIconName({ slug: areaId, cardType: CardType.SubscriptionCard })} />
            </div>
          </div>
        </Card>
      </a>
    </Link>
  )
}
